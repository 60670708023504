//@ts-ignore

import {
	CreativeQuesState,
	MatrixMatchQuesState,
	MethodologyType,
	MultipleChoiceQuesState,
	OptionType,
	QuesState,
	RankingQuesState,
	ScreenerQuesState,
} from '../../shared/types/QuesTypes';
import {
	MatrixResponseType,
	MultipleChoiceResponseState,
	MultipleOpinionScaleResponseType,
	RankingResponseState,
	ResponseState,
	ResponseType,
} from '../../shared/types/ResponseTypes';
import { QUES_TYPE } from '../../shared/consts/quesType';
import { and, append, compose, intersection, isEmpty, not, or } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { NONE_OF_THE_ABOVE_OPTION } from '../../shared/consts/questionare';
import { pipeResponse } from './pipeResponse';
import { MENTION_PREFIX } from '../../shared/utils/mentionUtils';
import { LogicalOperator } from '../../shared/types/QuestionLogic';
import { checkDisplayLogic } from '../SurveyBuilder/QuestionLogic/utils';
import { ResponseIndices } from '../../shared/enums/ResponseIndices';
import { parse as Parser, stringify } from '@lytejs/query-string';
import { MatrixAnswerType } from 'shared/types/QuestionSettings';
import parse from 'html-react-parser';
import { OptionResponse } from 'store/survey/surveyResponse/types';
import { QuestionsStates, SurveySectionsQuestionStates } from 'shared/types/SurveyResponses';
import { SECTIONS_IN_SURVEY_TYPE } from 'shared/consts/sectionType';
import cloneDeep from 'lodash.clonedeep';

export type ChoiceOption = {
	value: string;
	columnValue?: string;
	origin: string;
	text?: string;
	url?: string;
	optionType: OptionType;
	methodology?: MethodologyType;
	ranking?: number;
	orderPosition?: number;
	anchorOnRandomization?: boolean;
	parentOptionId?: string;
	groupAnchorOnRandomization?: boolean;
};

const optionInResponse =
	(response: OptionResponse[]) =>
	(option: ChoiceOption): boolean => {
		return !!response.find((o) => o.origin === option.origin);
	};

export const getOption = (
	question: MultipleChoiceQuesState | CreativeQuesState | MatrixMatchQuesState | ScreenerQuesState | RankingQuesState,
	optionId: string,
	responses: { [k: string]: ResponseState },
	allQuestions: { [k: string]: QuestionsStates },
	rootOption: string = optionId
): ChoiceOption | ChoiceOption[] => {
	const p = question.options[optionId]?.optionType === OptionType.Pipe && question.options[optionId]?.pipe;
	if (p) {
		const pipedFrom = allQuestions[p.pipeId] as MultipleChoiceQuesState | RankingQuesState;
		const questionResponse = responses[p.pipeId] as MultipleChoiceResponseState | RankingResponseState;
		const filter =
			p.pipeAnswer === 'all'
				? (_: OptionResponse) => true
				: p.pipeAnswer === 'selected'
				? optionInResponse(questionResponse?.response?.value || [])
				: compose(not, optionInResponse(questionResponse?.response?.value || []));

		return pipedFrom.settings.optionOrder
			.map((id) => getOption(pipedFrom, id, responses, allQuestions, rootOption))
			.flat()
			.filter(filter);
	}

	if (question.type === QUES_TYPE.CREATIVE_QUES) {
		const option = question.options[optionId];
		return {
			value: rootOption,
			origin: optionId,
			text:
				option.optionType === OptionType.Response
					? pipeResponse(responses, allQuestions, option.value)
					: option?.value || '',
			url: option?.url || '',
			optionType: option?.optionType || OptionType.Normal,
		};
	}
	const option = question.options[optionId];
	const response = (
		(responses[question.id] as MultipleChoiceResponseState | RankingResponseState)?.response?.value || []
	).find(({ origin }) => origin === optionId);
	return {
		value: option?.optionType === OptionType.Other ? ResponseIndices.OtherOption.toString() : rootOption,
		origin: optionId,
		orderPosition: option.orderPosition,
		anchorOnRandomization: option.anchorOnRandomization,
		parentOptionId: option.parentOptionId,
		groupAnchorOnRandomization: option.groupAnchorOnRandomization,
		text:
			option?.optionType === OptionType.None
				? option?.value
					? option?.value
					: NONE_OF_THE_ABOVE_OPTION
				: option?.optionType === OptionType.Other
				? response?.text || ''
				: option?.optionType === OptionType.Response
				? pipeResponse(responses, allQuestions, `${MENTION_PREFIX}${option.pipe?.pipeId}${MENTION_PREFIX}`)
				: option?.value || '',
		optionType: option?.optionType || OptionType.Normal,
		...(question.type === QUES_TYPE.SCREENER_QUES || !!question.parentQuestionId
			? { methodolody: option.methodology }
			: {}),
		...(question.type === QUES_TYPE.RANKING_QUES ? { orderPosition: option.orderPosition } : {}),
	};
};
export const getColumnOptions = (
	question: MatrixMatchQuesState,
	optionId: string,
	responses: { [k: string]: ResponseState },
	allQuestions: { [k: string]: QuestionsStates },
	rootOption: string = optionId
): ChoiceOption | ChoiceOption[] => {
	const p = question?.columnOptions[optionId]?.optionType === OptionType.Pipe && question.columnOptions[optionId]?.pipe;
	if (p) {
		const pipedFrom = allQuestions[p.pipeId] as MultipleChoiceQuesState;
		const questionResponse = responses[p.pipeId] as MultipleChoiceResponseState;
		const filter =
			p.pipeAnswer === 'all'
				? (_: OptionResponse) => true
				: p.pipeAnswer === 'selected'
				? optionInResponse(questionResponse?.response?.value || [])
				: compose(not, optionInResponse(questionResponse?.response?.value || []));

		return pipedFrom.settings.optionOrder
			.map((id) => getOption(pipedFrom, id, responses, allQuestions, rootOption))
			.flat()
			.filter(filter);
	}

	const option = question.columnOptions[optionId];
	const response = ((responses[question.id] as MultipleChoiceResponseState)?.response?.value || []).find(
		({ origin }) => origin === optionId
	);

	return {
		value: option?.optionType === OptionType.Other ? ResponseIndices.OtherOption.toString() : rootOption,
		origin: optionId,
		text:
			option?.optionType === OptionType.None
				? NONE_OF_THE_ABOVE_OPTION
				: option?.optionType === OptionType.Other
				? response?.text || ''
				: option?.optionType === OptionType.Response
				? pipeResponse(responses, allQuestions, `${MENTION_PREFIX}${option.pipe?.pipeId}${MENTION_PREFIX}`)
				: option?.value || '',
		optionType: option?.optionType || OptionType.Normal,
	};
};
export const getRankingByResponse = (option, response, keyElement = 'ranking') => {
	const res: any = ((response as RankingResponseState)?.response?.value || []).find(
		({ origin }) => origin === option.origin
	);
	return res ? res[keyElement] : null;
};
export const getDefaultResponse: (ques: QuesState) => ResponseType | undefined = (ques) => {
	switch (ques?.type) {
		case QUES_TYPE.CREATIVE_QUES:
		case QUES_TYPE.SCREENER_QUES:
		case QUES_TYPE.MULTIPLE_CHOICE_QUES: {
			return {
				value: [],
			};
		}

		case QUES_TYPE.NUMBER_QUES: {
			return NaN;
		}

		case QUES_TYPE.RATING_QUES:
		case QUES_TYPE.YES_NO_QUES:
		case QUES_TYPE.OPINION_SCALE_QUES: {
			return undefined;
		}

		case QUES_TYPE.MULTIPLE_OPINION_SCALE_QUES: {
			const response: MultipleOpinionScaleResponseType = {};
			ques.settings.sectionOrder.forEach((sectionId: string) => {
				response[sectionId] = undefined;
			});
			return response;
		}

		case QUES_TYPE.MATRIX_QUES: {
			const response: MatrixResponseType = {};
			ques.settings.columnOrder.forEach((columnId: string) => {
				response[columnId] = {
					value: [],
				};
			});
			return response;
		}

		case QUES_TYPE.MATRIX_MATCH_QUES: {
			return [];
		}

		case QUES_TYPE.IMAGE_QUES: {
			return {
				value: {},
				showImage: false,
			};
		}

		case QUES_TYPE.LINEAR_RANGE_QUES: {
			return undefined;
		}
	}
};

export const appendPositionPrefix = (question: Partial<QuesState>) => `Q${question?.orderPosition}.`;

export const computeSelectedOptions = (
	previouslySelected: OptionResponse[],
	option: OptionResponse,
	isMultiple: boolean,
	isInput?: boolean,
	optionType?: OptionType,
	options?: any
) => {
	let willBeMultipleType = isMultiple,
		updatedPrevoiuslySelected = [...previouslySelected];

	if (optionType === OptionType.None) {
		willBeMultipleType = false;
	} else {
		updatedPrevoiuslySelected = previouslySelected.filter(
			(prevSelected) => prevSelected.optionType !== OptionType.None
		);
	}
	const checkSelectedOption = options.map((o) => o.origin);
	updatedPrevoiuslySelected = previouslySelected.filter((o) => checkSelectedOption.includes(o.origin));
	return willBeMultipleType
		? updatedPrevoiuslySelected.some((o) => o.origin === option.origin)
			? isInput
				? updatedPrevoiuslySelected
				: updatedPrevoiuslySelected.filter((o) => o.origin !== option.origin)
			: append(option)(updatedPrevoiuslySelected)
		: [option];
};

export const isOptionSelected = (option: ChoiceOption, response?: OptionResponse[]): boolean => {
	return (response || []).some(({ origin }) => origin === option.origin);
};

export enum LogicCheckAction {
	SkipToNext = 'SkipToNext',
	SkipToQuestion = 'SkipToQuestion',
	SkipToLink = 'SkipToLink',
	SkipToEnd = 'SkipToEnd',
}
export const checkLogics = (responses, arr) => {
	let isValid = true;
	for (let c of arr) {
		const response = responses[c.questionId]?.response;
		const isGood = checkDisplayLogic(response as any, c.optionLogic);
		if (!c.logic) {
			isValid = isGood;
		} else if (c.logic === LogicalOperator.And) {
			isValid = and(isValid, isGood);
		} else {
			if (isValid) {
				return isValid;
			}
			isValid = or(isValid, isGood);
		}
	}
	return isValid;
};
export const getQuestionByDisplayLogic = (responses, questions) => {
	if (!questions) return;
	let displayQuestion: any = {};

	const questionWithLogic = Object.values(questions).filter(
		(question: any) => question?.type === QUES_TYPE.THANK_YOU && question.logic.display.showIf.length
	);
	const questionWithSkipLogic = Object.values(questions).filter(
		(question: any) => question.logic.display.skipIf.length
	);
	if (questionWithLogic.length) {
		for (let question of questionWithLogic) {
			const {
				display: { showIf },
				//@ts-ignore
			} = question.logic;
			const shouldShow = checkLogics(responses, showIf);
			if (shouldShow) {
				displayQuestion = question;
				break;
			}
		}
	}
	if (isEmpty(displayQuestion)) {
		displayQuestion = Object.values(questions).find(
			(question: any) => question?.type === QUES_TYPE.THANK_YOU && !question.logic.display.showIf.length
		);
	}
	if (questionWithSkipLogic.length) {
		for (let question of questionWithSkipLogic) {
			const {
				display: { skipIf },
				//@ts-ignore
			} = question.logic;
			const shoulSkip = checkLogics(responses, skipIf);
			if (shoulSkip) {
				displayQuestion = Object.values(questions).find(
					(question: any) => question?.id === skipIf[0].action.value && skipIf[0].action.type === 'thankyou'
				);
				break;
			}
			if (!displayQuestion) {
				displayQuestion = Object.values(questions).find(
					(question: any) => question?.type === QUES_TYPE.THANK_YOU && !question.logic.display.showIf.length
				);
			}
		}
	}
	return displayQuestion;
};
export const checkLogic = (
	responses: { [k: string]: ResponseState },
	question?: QuestionsStates
): { reason: LogicCheckAction; value?: string } | undefined => {
	if (!question) return;

	if (question?.logic) {
		// for normal question
		const {
			display: { showIf, skipIf },
		} = question?.logic;

		if (skipIf && skipIf.length) {
			const shouldSkip = checkLogics(responses, skipIf);
			if (shouldSkip) {
				let checkLogicResult;
				for (const logic of skipIf) {
					const response = responses[logic?.questionId]?.response;
					if (checkDisplayLogic(response as any, logic.optionLogic)) {
						if (['question', 'thankyou']?.includes(logic?.action?.type as any)) {
							checkLogicResult = {
								reason: LogicCheckAction.SkipToQuestion,
								value: logic.action.value,
							};
						} else if (logic.action.type === 'link') {
							return {
								reason: LogicCheckAction.SkipToLink,
								value: logic.action.value,
							};
						} else {
							checkLogicResult = {
								reason: LogicCheckAction.SkipToEnd,
							};
						}
					}
				}
				return checkLogicResult;
			}
		}

		if (showIf && showIf.length) {
			const shouldShow = checkLogics(responses, showIf);
			if (!shouldShow) {
				return {
					reason: LogicCheckAction.SkipToNext,
				};
			}
		}
	} else if (question?.display) {
		// for normal question
		const { showIf, skipIf = [] } = question?.display;

		if (skipIf && skipIf.length) {
			const shouldSkip = checkLogics(responses, skipIf);
			if (shouldSkip) {
				let checkLogicResult;
				for (const logic of skipIf) {
					const response = responses[logic?.questionId]?.response;
					if (checkDisplayLogic(response as any, logic.optionLogic)) {
						if (['question', 'thankyou']?.includes(logic?.action?.type as any)) {
							checkLogicResult = {
								reason: LogicCheckAction.SkipToQuestion,
								value: logic.action.value,
							};
						} else if (logic.action.type === 'link') {
							return {
								reason: LogicCheckAction.SkipToLink,
								value: logic.action.value,
							};
						} else {
							checkLogicResult = {
								reason: LogicCheckAction.SkipToEnd,
							};
						}
					}
				}
				return checkLogicResult;
			}
		}

		if (showIf && showIf.length) {
			const shouldShow = checkLogics(responses, showIf);
			if (!shouldShow) {
				return {
					reason: LogicCheckAction.SkipToNext,
				};
			}
		}
	}
};

export const getSurveyFinalRedirectURL = (redirectURLs) => {
	const URLVariables = Parser(window.location.search.substring(1));
	let panel = URLVariables.panel;
	let finalRedirectURL = '';
	if (panel) {
		const redirectURL = redirectURLs.find((redirectURL) => redirectURL.panel === panel);
		if (redirectURL) {
			const redirectURLChunks = redirectURL.url.split('?');
			const redirectURLQuery = redirectURLChunks[1] || '';
			const redirectURLVariables = Parser(redirectURLQuery);
			for (let redirectURLVariable in redirectURLVariables) {
				redirectURLVariables[redirectURLVariable] =
					URLVariables[redirectURLVariable] ||
					URLVariables[redirectURLVariable.toLowerCase()] ||
					redirectURLVariables[redirectURLVariable];
			}
			finalRedirectURL = `${redirectURLChunks[0]}?${stringify(redirectURLVariables)}`;
		}
	}
	return finalRedirectURL;
};

export const getSkipLogicRedirectLink = (link) => {
	const URLVariables = Parser(window.location.search.substring(1));
	//@ts-ignore
	let panel = URLVariables.panel;
	let finalRedirectURL = link;
	if (panel) {
		if (link) {
			const redirectURLChunks = link.split('?');
			const redirectURLQuery = redirectURLChunks[1];
			const redirectURLVariables = Parser(redirectURLQuery);
			//@ts-ignore
			for (let redirectURLVariable in redirectURLVariables) {
				redirectURLVariables[redirectURLVariable] =
					URLVariables[redirectURLVariable] ||
					URLVariables[redirectURLVariable.toLowerCase()] ||
					redirectURLVariables[redirectURLVariable];
			}
			//@ts-ignore
			finalRedirectURL = `${redirectURLChunks[0]}?${stringify(redirectURLVariables)}`;
		}
	}
	return finalRedirectURL;
};

export const getSurveyLink = (link) => {
	if (link.includes('?')) {
		const getPanelLink = getSkipLogicRedirectLink(link);
		return getPanelLink;
	} else {
		return link;
	}
};

export enum LogicType {
	IS = 'is',
	IS_NOT = 'isNot',
	STARTS_WITH = 'startsWith',
	ENDS_WITH = 'endsWith',
	HAS_ANY_VALUE = 'hasAnyValue',
	HAS_NO_VALUE = 'hasNoValue',
	CONTAINS = 'contains',
	DOES_NOT_CONTAINS = 'doesNotContains',
	GREATER_THAN = 'greaterThan',
	LESS_THAN = 'lessThan',
}
export const getLimitCheck = (settings, response) => {
	const { min, max, type, exact, multipleAnswers } = settings;
	if (!response?.value.length) return false;
	if (multipleAnswers) {
		if (response?.value?.slice(-1)?.[0]?.optionType === 'none') {
			return false;
		}
		if (type === 'Exact' && exact && exact !== response?.value.length) {
			return `Please select ${exact} option${exact === 1 ? '' : 's'}`;
		}
		if (type === 'Range' && min && min > response?.value.length) {
			return `Please select atleast ${min} option${min === 1 ? '' : 's'}`;
		}
		if (type === 'Range' && max && max < response?.value.length) {
			return `The maximum number of selection is limited to ${max} option${max === 1 ? '' : 's'}`;
		}
	}
	return false;
};
export const getColumnRange = (column, option) => {
	column.sort((a, b) => a.orderPosition - b.orderPosition);
	return column
		.slice(
			column.findIndex(({ text }) => text === option.minRange),
			column.findIndex(({ text }) => text === option.maxRange) + 1
		)
		.map(({ orderPosition }) => orderPosition);
};
export const getRowsWithColumnIntersection = (selectedColumnRange, response, singleCorrect) => {
	return response.map((option) => {
		const commonOptionFromSelectedColumnRange = intersection(selectedColumnRange, option[1]);
		const isCommonOption = commonOptionFromSelectedColumnRange.length ? true : false;
		return [
			option[0],
			isCommonOption,
			// intersection(selectedColumnRange, option[1]).length >= (singleCorrect ? 1 : selectedColumnRange.length),
		];
	});
};
export const getRowsWithRangesIntersection = (selectedColumnRanges, response, singleCorrect) => {
	return selectedColumnRanges.map(({ logic, range, row }) => {
		const commonOptionFromRange = intersection(range, response.find((option) => option[0] === row)[1]);
		const isCommonOption = commonOptionFromRange.length ? true : false;
		return [
			row,
			// intersection(range, response.find((option) => option[0] === row)[1]).length >= (singleCorrect ? 1 : range.length)
			isCommonOption,
			logic,
		];
	});
};
const checkMandate = (selectedRows) => {
	var isValid = true;
	for (var c of selectedRows) {
		if (!c[2]) {
			isValid = c[1];
		} else if (c[2] === LogicalOperator.And) {
			isValid = and(isValid, c[1]);
		} else {
			if (isValid) {
				return isValid;
			}
			isValid = or(isValid, c[1]);
		}
	}
	return isValid;
};
export const getMandateLogic = (question, response) => {
	const {
		column,
		mandate,
		settings: { answerType },
	} = question;
	const singleCorrect = answerType === MatrixAnswerType.SingleCorrect;
	if (!mandate) return false;
	const { options, type } = mandate;
	const { minRange, rowNumber, specificRow, maxRange } = options[0];
	if (!type.length || !minRange || !maxRange) return false;
	if (type === 'any rows') {
		if (!rowNumber) return false;
		const selectedColumnRange = getColumnRange(column, options[0]);
		const selectedRows = getRowsWithColumnIntersection(selectedColumnRange, response, singleCorrect);
		const isMandate = selectedRows.filter((option) => option[1]).length >= options[0].rowNumber;
		if (isMandate) return false;
	}
	if (type === 'specific rows') {
		if (!specificRow[0]) return false;
		const selectedColumnRanges = options.map((option) => ({
			logic: option.logic,
			range: getColumnRange(column, option),
			row: option.specificRow[0],
		}));
		const selectedRows = getRowsWithRangesIntersection(selectedColumnRanges, response, singleCorrect);
		const isMandate = checkMandate(selectedRows);
		if (isMandate) return false;
	}
	if (type === 'any + specific rows') {
		if (!specificRow[0] || !rowNumber) return false;
		const selectedColumnRange = getColumnRange(column, options[0]);
		const selectedRows = getRowsWithColumnIntersection(selectedColumnRange, response, singleCorrect);
		const isMandate =
			selectedRows.filter((option) => options[0]?.specificRow.includes(option[0]) && option[1]).length >=
			options[0].rowNumber;
		if (isMandate) return false;
	}
	return true;
};

export const checkTextTag = (text) => {
	let result = text?.replace(/<\/?p>/g, '') || text;
	const checkTags = ((typeof result === 'string' && result) || '').match(/(<([^>]+)>)/gi);
	if (checkTags?.length) {
		return parse(result);
	}
	return result;
};
export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};
export function extractContent(s) {
	var span = document.createElement('span');
	span.innerHTML = s;
	return span.textContent || span.innerText;
}

export const welcomeScreenerSurveyDesc =
	'As part of this survey session we are looking to speak with individuals like you. You will need a laptop with webcam and screen sharing option. This survey involves a few screening questions which should take less than 15 minutes. To answer, click “Continue”';

export const themeSelector = {
	stock: 'stock',
	dental: 'dental_template1',
	education: 'education_template1',
};
export const excludedQuotaQuestions = [
	QUES_TYPE.WELCOME_SCREEN,
	QUES_TYPE.SCREENER_QUES,
	QUES_TYPE.VIDEO_UPLOAD_QUES,
	QUES_TYPE.MATRIX_MATCH_QUES,
	QUES_TYPE.TASK_QUES,
	QUES_TYPE.IMAGE_QUES,
	QUES_TYPE.MULTIPLE_OPINION_SCALE_QUES,
];

function checkData(quotaResponse, logic, filledResponse) {
	// eslint-disable-next-line no-param-reassign
	if (typeof quotaResponse === 'number') quotaResponse = quotaResponse as unknown as string;
	// eslint-disable-next-line no-param-reassign
	if (typeof filledResponse?.response === 'number') filledResponse['response'] = `${filledResponse?.response}`;
	switch (logic) {
		// case 1: Number text Email LS.
		case LogicType.IS:
			// can have array or not
			return quotaResponse === filledResponse?.response;
		case LogicType.IS_NOT:
			return quotaResponse !== filledResponse?.response;
		case LogicType.CONTAINS:
			return typeof filledResponse?.response === 'string' ? filledResponse?.response?.includes(quotaResponse) : false;
		case LogicType.DOES_NOT_CONTAINS:
			return typeof filledResponse?.response === 'string' ? !filledResponse?.response?.includes(quotaResponse) : false;
		case LogicType.HAS_ANY_VALUE:
			return !['', 'NaN'].includes(filledResponse?.response);
		case LogicType.GREATER_THAN:
			return Number(filledResponse?.response) > Number(quotaResponse);
		case LogicType.LESS_THAN:
			return Number(filledResponse?.response) < Number(quotaResponse);
		case LogicType.HAS_NO_VALUE:
			const numberResponse = filledResponse?.response === 'NaN' ? '' : filledResponse?.response;
			return ['', 'NaN', undefined].includes(numberResponse);
		case LogicType.STARTS_WITH:
			return typeof filledResponse?.response === 'string' ? filledResponse?.response?.startsWith(quotaResponse) : false;
		case LogicType.ENDS_WITH:
			return typeof filledResponse?.response === 'string' ? filledResponse?.response?.endsWith(quotaResponse) : false;
		// Case 2: OS/MCQ/Rating/Yes-No/Creative
		case '':
			// multiple values of array are in or condition or in and condition
			// filled response will be of list quota will be of map
			// eslint-disable-next-line no-case-declarations
			let condn = false;
			if (typeof quotaResponse === 'object' && typeof filledResponse === 'object') {
				if (filledResponse.type === QUES_TYPE.YES_NO_QUES) {
					let value = filledResponse.response ? 'yes' : filledResponse.response === false ? 'no' : false;
					condn = quotaResponse.includes(value);
				}
				if ([QUES_TYPE.OPINION_SCALE_QUES, QUES_TYPE.RATING_QUES].includes(filledResponse.type)) {
					condn = quotaResponse.includes(filledResponse.response);
				}
				if ([QUES_TYPE.MULTIPLE_CHOICE_QUES, QUES_TYPE.CREATIVE_QUES].includes(filledResponse.type)) {
					const responses = filledResponse.response?.value?.map((val) => val.origin);
					const isValuepresent = responses.filter((id) => quotaResponse.includes(id));
					condn = isValuepresent?.length;
				}
			}
			return condn;
		default:
			return false;
	}
}

function fetchDataFromQuestionType(response, inputType = '') {
	let dataMap = [];
	switch (response?.questionType) {
		// CASE: 1 logic will always be ''
		case QUES_TYPE.MULTIPLE_CHOICE_QUES:
			if (inputType === 'quota') {
				dataMap = response?.response?.map((res) => res.value);
			} else {
				dataMap = response?.response?.map((res) => res.value);
			}
			return dataMap;

		case QUES_TYPE.CREATIVE_QUES:
			if (inputType === 'quota') {
				dataMap = response?.response?.map((res) => res.value);
			} else {
				dataMap = response?.response?.map((res) => res.value);
			}
			return dataMap;

		case QUES_TYPE.YES_NO_QUES:
			if (inputType === 'quota') {
				dataMap = response?.response?.map((res) => res.value);
			} else {
				dataMap = response?.response?.map((res) => res.value);
			}
			return dataMap; // return true or false;

		case QUES_TYPE.OPINION_SCALE_QUES:
			if (inputType === 'quota') {
				dataMap = response?.response?.map((res) => res.value);
			} else {
				dataMap = response?.response?.map((res) => res.value);
			}
			return dataMap;

		case QUES_TYPE.RATING_QUES:
			if (inputType === 'quota') {
				dataMap = response?.response?.map((res) => res.value);
			} else {
				dataMap = response?.response?.map((res) => res.value);
			}
			return dataMap;

		// CASE: 2 logic is other than ''
		case QUES_TYPE.LINEAR_RANGE_QUES:
			return response?.response;

		case QUES_TYPE.TEXT_BOX_QUES:
			return response?.response;

		case QUES_TYPE.NUMBER_QUES:
			return response?.response;

		case QUES_TYPE.EMAIL_QUES:
			return response?.response;

		default:
			return [];
	}
}
function checkCustomVariableData(quotaResponse, logic, filledResponse) {
	switch (logic) {
		// case 1: Number text Email LS.
		case LogicType.IS:
			// can have array or not

			return quotaResponse === filledResponse;
		case LogicType.IS_NOT:
			return quotaResponse !== filledResponse;
		case LogicType.CONTAINS:
			return typeof filledResponse === 'string' ? filledResponse?.includes(quotaResponse) : false;
		case LogicType.DOES_NOT_CONTAINS:
			return typeof filledResponse === 'string' ? !filledResponse?.includes(quotaResponse) : false;
		case LogicType.HAS_ANY_VALUE:
			return !['', 'NaN'].includes(filledResponse);
		case LogicType.HAS_NO_VALUE:
			return ['', 'NaN', undefined].includes(filledResponse);
		case LogicType.STARTS_WITH:
			return typeof filledResponse === 'string' ? filledResponse?.startsWith(quotaResponse) : false;
		case LogicType.ENDS_WITH:
			return typeof filledResponse === 'string' ? filledResponse?.endsWith(quotaResponse) : false;
		default:
			return false;
	}
}
export const CheckQuotaFulfilled = (quota, responses, isCustomVariable) => {
	let outerConditionCheck = [];
	quota?.quotaConditionList?.forEach((conditionArray: []) => {
		let innerConditionCheck: any = [];
		if (isCustomVariable) {
			const checkCustom = conditionArray.every(
				(conditionObj: { QuotaOn: string; recordId: string; apiIdentifier: string; response: any; logic: string }) =>
					conditionObj?.QuotaOn === 'questionAnswer'
			);
			if (checkCustom) {
				return innerConditionCheck.push(false);
			}
		}
		conditionArray.map(
			(conditionObj: { QuotaOn: string; recordId: string; apiIdentifier: string; response: any; logic: string }) => {
				if (conditionObj?.QuotaOn === 'questionAnswer') {
					let responseData = responses[conditionObj?.recordId];
					if (responses.hasOwnProperty(conditionObj?.recordId)) {
						innerConditionCheck.push(
							//@ts-ignore
							checkData(fetchDataFromQuestionType(conditionObj, 'quota'), conditionObj.logic, responseData)
						);
						return;
					}
					return innerConditionCheck.push(false);
				}
				if (conditionObj?.QuotaOn === 'customVariableKey') {
					const url = window.location.search;
					const URLVariables = Parser(url.split('?')[1]);
					const variableString = URLVariables[conditionObj?.apiIdentifier] || '';
					innerConditionCheck.push(
						//@ts-ignore
						checkCustomVariableData(conditionObj?.response, conditionObj.logic, variableString)
					);
					return;
				}
				return innerConditionCheck.push(false);
			}
		);
		//@ts-ignore
		return outerConditionCheck.push(innerConditionCheck.every((value) => value));
	});
	return outerConditionCheck.some((value) => value) ? quota.limit === quota.quotaResponseCount : false;
};

export const getQuotaConditionFulfilled = (quotaList, responses, isCustomVariable, question, questions) => {
	let fillResponses: any = [];
	const selectedQuestions = Object.values(questions)?.filter(
		(ques) => (ques as any)?.orderPosition <= question.orderPosition
	);
	const includedQuestionsList = selectedQuestions
		?.filter((question) => !excludedQuotaQuestions.includes((question as any)?.type as any))
		.map((question) => (question as any)?.id);
	const sortedResponses = includedQuestionsList.reduce((accum, current) => {
		accum = { ...accum, [current]: responses[current] };
		return accum;
	}, {});
	quotaList?.entities?.forEach((quota) => {
		let check = CheckQuotaFulfilled(quota, sortedResponses, isCustomVariable);
		//@ts-ignore
		fillResponses.push({ redirectUrl: quota.url, check, endWith: quota.endWith });
		// return fillResponses.some(value=>value)
	});
	return fillResponses;
};

export const getNoneOfTheAboveOptions = (newFilteredOptionList, question, responses) => {
	const options: any = Object.values(question.options);
	let newOptions = newFilteredOptionList;
	const noneOptions = options.filter((option): any => option?.optionType === OptionType.Response);
	const structerizeOptions = noneOptions.map((option: any) => ({
		...(responses[option?.pipe?.pipeId]?.response?.value
			? responses[option?.pipe?.pipeId]?.response?.value[0]
			: responses[option?.pipe?.pipeId]?.response),
		id: option?.id,
	}));
	const checkNoneOptions = structerizeOptions.filter((option) => option.optionType === OptionType.None);
	if (checkNoneOptions.length) {
		newOptions = newFilteredOptionList.filter(
			(option) => checkNoneOptions.findIndex((opt) => option?.origin === opt?.id) < 0
		);
	}
	return newOptions;
};

export const getCurrentTemplate = (surveyTemplateSetting) => {
	const selectedId = surveyTemplateSetting?.surveyTemplate?.selectedTemplateId;
	const templates = surveyTemplateSetting?.surveyTemplate?.mySurveyTemplate?.concat(
		surveyTemplateSetting?.surveyTemplate?.exploreSurveyTemplate
	);
	// return selectedId === surveyTemplateSetting?.customize?.id
	// 	? surveyTemplateSetting?.customize
	// 	: templates?.find((template) => template.id === selectedId)
	// 	? templates?.find((template) => template.id === selectedId)
	// 	: surveyTemplateSetting?.customize;
	return templates?.find((template) => template.id === selectedId)
		? templates?.find((template) => template.id === selectedId)
		: surveyTemplateSetting?.customize;
};

export const deepCompareObjects = (obj1, obj2) => {
	if (obj1 === obj2) return true;

	if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false;

	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if (keys1.length !== keys2.length) return false;

	for (const key of keys1) {
		if (!keys2.includes(key)) return false;

		if (!deepCompareObjects(obj1[key], obj2[key])) return false;
	}

	return true;
};
export const getupdatedTemplateSetting = (templateSetting) => {
	let settings = templateSetting;
	settings.surveyTemplate.exploreSurveyTemplate = templateSetting?.surveyTemplate?.exploreSurveyTemplate.map((temp) => {
		return exploreThemesAdditionalKeys[temp.themeName]
			? {
					...temp,
					color: { ...temp.color, ...exploreThemesAdditionalKeys[temp.themeName].color },
					fontStyle: exploreThemesAdditionalKeys[temp.themeName]?.fontStyle,
					backgroundSettings: exploreThemesAdditionalKeys[temp.themeName]?.backgroundSettings
						? exploreThemesAdditionalKeys[temp.themeName]?.backgroundSettings
						: temp?.backgroundSettings,
			  }
			: { ...temp };
	});
	return settings;
};
export const exploreThemesAdditionalKeys = {
	'EXPLORE THEME 1': {
		id: 'EXPLORE THEME 1',
		color: {
			descriptionColor: 'rgba(175, 175, 175, 0.8)',
			answersBackground: 'rgba(175, 175, 175, 0.5)',
			buttonTextColor: '#000',
			answerSelected: '#000000',
			answerSelectedBackground: '#ffffff',
			linearSelectorColor: '#6B38C4',
			matrixBorderColor: 'rgba(175, 175, 175, 0.5)',
			selectedBoxColor: '#6B38C4',
			skipButtonTextColor: '#ffffff',
		},
		fontStyle: 'Inter',
	},
	'EXPLORE THEME 2': {
		id: 'EXPLORE THEME 2',
		color: {
			descriptionColor: 'rgba(45, 45, 45, 0.6)',
			answersBackground: '#000000',
			buttonTextColor: '#ffffff',
			answerSelected: '#ffffff',
			answerSelectedBackground: '#6D9D15',
			linearSelectorColor: '#6D9D15',
			selectedBoxColor: '#000000',
			matrixBorderColor: 'rgba(131, 131, 131, 0.3)',
			skipButtonTextColor: '#6D9D15',
		},

		fontStyle: 'Montserrat',
		backgroundSettings: {
			backgroundSettingType: '',
			gradient: {
				backgroundColor: '',
			},
			image: {
				backgroundImageURL:
					'https://d3gwdsy0ns7b47.cloudfront.net/insights_themes/explore_theme_background/Theme_02_xabkry.svg',
				brightness: null,
				blending: '',
				isSmartPickColorEnalbled: false,
				file: null,
			},
		},
	},
	'EXPLORE THEME 3': {
		id: 'EXPLORE THEME 3',
		color: {
			descriptionColor: 'rgba(175, 175, 175, 0.8)',
			answersBackground: 'rgba(175, 175, 175, 0.5)',
			buttonTextColor: '#000000',
			answerSelected: '#000000',
			linearSelectorColor: '#6B38C4',
			answerSelectedBackground: '#ffffff',
			selectedBoxColor: '#6B38C4',
			matrixBorderColor: 'rgba(175, 175, 175, 0.5)',
			skipButtonTextColor: '#ffffff',
		},

		fontStyle: 'Cinzel',
	},
	'EXPLORE THEME 4': {
		id: 'EXPLORE THEME 4',
		color: {
			descriptionColor: 'rgba(45, 45, 45, 0.6)',
			answersBackground: 'rgba(175, 175, 175, 0.5)',
			buttonTextColor: '#ffffff',
			answerSelected: '#ffffff',
			linearSelectorColor: '#2B6D9C',
			selectedBoxColor: '#000000',
			matrixBorderColor: 'rgba(131, 131, 131, 0.3)',
			skipButtonTextColor: '#3E6BDE',
			answerSelectedBackground: '#2B6D9C',
		},
		fontStyle: 'Cinzel',
	},
	'EXPLORE THEME 5': {
		id: 'EXPLORE THEME 5',
		color: {
			descriptionColor: 'rgba(45, 45, 45, 0.6)',
			answersBackground: 'rgba(175, 175, 175, 0.5)',
			buttonTextColor: '#ffffff',
			answerSelected: '#ffffff',
			linearSelectorColor: '#67771D',
			selectedBoxColor: '#000000',
			matrixBorderColor: 'rgba(131, 131, 131, 0.3)',
			skipButtonTextColor: '#000000',
			answerSelectedBackground: '#67771D',
		},
		fontStyle: 'Lato',
	},
	'EXPLORE THEME 6': {
		id: 'EXPLORE THEME 6',
		color: {
			descriptionColor: 'rgba(175, 175, 175, 0.8)',
			answersBackground: 'rgba(175, 175, 175, 0.5)',
			buttonTextColor: '#000000',
			answerSelected: '#000000',
			linearSelectorColor: '#6B38C4',
			selectedBoxColor: '#6B38C4',
			matrixBorderColor: 'rgba(175, 175, 175, 0.5)',
			skipButtonTextColor: '#FFFFFF',
			answerSelectedBackground: '#ffffff',
			additionalBackground: 'rgba(0, 0, 0, 0.4)',
		},
		fontStyle: 'Inter',
	},
	'EXPLORE THEME 7': {
		id: 'EXPLORE THEME 7',
		color: {
			descriptionColor: 'rgba(0, 0, 0, 0.6)',
			answersBackground: 'rgba(175, 175, 175, 0.5)',
			buttonTextColor: '#ffffff',
			answerSelected: '#ffffff',
			linearSelectorColor: '#E3A32D',
			selectedBoxColor: '#000000',
			matrixBorderColor: 'rgba(131, 131, 131, 0.3)',
			skipButtonTextColor: '#EDA013',
			answerSelectedBackground: '#E3A32D',
		},
		fontStyle: 'Cinzel',
	},
	'EXPLORE THEME 8': {
		id: 'EXPLORE THEME 8',
		color: {
			descriptionColor: 'rgba(175, 175, 175, 0.8)',
			answersBackground: 'rgba(175, 175, 175, 0.5)',
			buttonTextColor: '#000',
			answerSelected: '#000000',
			linearSelectorColor: '#6B38C4',
			selectedBoxColor: '#6B38C4',
			matrixBorderColor: 'rgba(175, 175, 175, 0.5)',
			skipButtonTextColor: '#ffffff',
			answerSelectedBackground: '#ffffff',
		},
		fontStyle: 'Lato',
	},
	'EXPLORE THEME 9': {
		id: 'EXPLORE THEME 9',
		color: {
			descriptionColor: 'rgba(0, 0, 0, 0.6)',
			answersBackground: 'rgba(175, 175, 175, 0.5)',
			buttonTextColor: '#ffffff',
			answerSelected: '#ffffff',
			linearSelectorColor: '#5B82CD',
			selectedBoxColor: '#000000',
			matrixBorderColor: 'rgba(131, 131, 131, 0.3)',
			skipButtonTextColor: '#000000',
			answerSelectedBackground: '#5B82CD',
		},
		fontStyle: 'Montserrat',
	},
	'EXPLORE THEME 10': {
		id: 'EXPLORE THEME 10',
		color: {
			descriptionColor: 'rgba(0, 0, 0, 0.6)',
			answersBackground: 'rgba(175, 175, 175, 0.5)',
			buttonTextColor: '#ffffff',
			answerSelected: '#ffffff',
			linearSelectorColor: '#1D6AB',
			selectedBoxColor: '#000000',
			matrixBorderColor: 'rgba(131, 131, 131, 0.3)',
			skipButtonTextColor: '#1D6AB0',
			answerSelectedBackground: '#1D6AB0',
		},
		fontStyle: 'Lato',
		backgroundSettings: {
			backgroundSettingType: '',
			gradient: {
				backgroundColor: '',
			},
			image: {
				backgroundImageURL:
					'https://d3gwdsy0ns7b47.cloudfront.net/insights_themes/explore_theme_background/Theme_10_h59al0.svg',
				brightness: null,
				blending: '',
				isSmartPickColorEnalbled: false,
				file: null,
			},
		},
	},
};
export const defaultTheme = {
	id: uuidv4(),
	themeName: 'Untitled theme',
	brandName: '',
	footerNotes: '',
	showHeader: false,
	showFooter: false,
	showQuestionNumber: false,
	isMandatoryQuestions: false,
	brandLogoURL: '',
	iconImageURL: null,
	questionnaireId: null,
	orderPosition: 0,
	showProgressBar: true,
	isRoundedButton: false,
	buttonStyle: {
		filled: true,
		border: false,
	},
	fontStyle: 'Inter',
	alignment: {
		left: false,
		center: true,
	},
	backgroundSettings: {
		backgroundSettingType: {
			image: true,
			gradient: false,
		},
		gradient: {
			backgroundColor: '',
		},
		image: {
			backgroundImageURL: '',
			brightness: 100,
			blending: 'normal',
			isSmartPickColorEnalbled: false,
			file: null,
		},
	},
	color: {
		questionsColor: '#152370',
		answersColor: '#4253A9',
		backgroundColor: '#FCFCFF',
		buttonsColor: '#7335CB',
		errorsColor: '#eb4034',
		descriptionColor: '',
		answersBackground: '',
		buttonTextColor: '',
		answerSelected: '',
		answerSelectedBackground: '',
		linearSelectorColor: '',
		matrixBorderColor: '',
		selectedBoxColor: '',
		additionalBackground: '',
		skipButtonTextColor: '',
	},
};

export const getAddNumberCheck = (
	requiredColumns,
	matrixResponse,
	sanitizeOptions,
	errorCheck = false,
	textValue = null
) => {
	if (!matrixResponse) return false;
	const initOptionWiseSelection: Record<string, number[]> = Object.fromEntries(
		requiredColumns.map(({ text }) => [text, []])
	);
	const optionWiseSelection = matrixResponse.reduce((acc, selection) => {
		const { columnText, addNumber } = selection;
		const clonedAcc = cloneDeep(acc);
		if (clonedAcc[columnText]) clonedAcc[columnText].push(addNumber);
		// else clonedAcc[columnText] = [addNumber];
		return clonedAcc;
	}, initOptionWiseSelection);

	//@ts-ignore
	return Object.values(!!textValue ? { [textValue]: optionWiseSelection?.textValue } : optionWiseSelection).every(
		(optionWiseSelectedColumns: any) => {
			//@ts-ignore
			const colSum = optionWiseSelectedColumns?.reduce((acc, curr) => acc + curr, 0);
			return errorCheck
				? sanitizeOptions.length === optionWiseSelectedColumns?.length
				: colSum === 100 && sanitizeOptions.length === optionWiseSelectedColumns?.length;
		}
	);
};
